
.dropdown {
    background-color: white;
    display: flex;
    flex-direction: column;
    border: 1px solid gray;
  }
  
  .dropdown-row {
    cursor: pointer;
    text-align: start;
    margin: 2px 0;
  }

  .dropdown:empty { 
    border: none;
  }

  .taquilla-omnibus-butacas {
    display: flex;
    flex-direction: column;
    margin: 0 10px;
      margin-left: 10px;
  }