.custom-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1050;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
    max-width: 1000px;
    background: rgb(255, 255, 255);
    border-radius: 0.3rem;
    overflow: hidden;
}

.custom-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1040;
}
.ctn-tel{
    padding-left: 5px;
}

@media (max-width:1000px) {
    .custom-modal {
        width: 60%;
    } 
}

@media (max-width: 768px) {
    .custom-modal {
        width: 90%;
    }
}

/* Estilos responsivos para pantallas pequeñas */
@media (max-width: 576px) {

    .modal-dialog-passenger-modal {
        padding: 1rem;
        max-height: 650px;
        overflow: auto !important;
    }

    .modal-header-passenger-modal {
        font-size: 1rem; /* Reducir el tamaño del título */
    }

    .modal-body-passenger-modal label {
        font-size: 0.9rem; /* Reducir el tamaño de las etiquetas */
    }

    .form-control-passenger-modal {
        font-size: 0.7rem; /* Reducir el tamaño de la tipografía de los inputs */
        padding: 0.4rem 0.6rem; /* Reducir el padding */
        min-height: 0px;
        margin-bottom: 0.35rem;
    }

    .form-group-passenger-modal {
        margin-bottom: 0.5rem; /* Reducir espacio entre los grupos de formulario */
    }

    .btn_1-passenger-modal {
        font-size: 0.9rem; /* Reducir el tamaño de los botones */
        padding: 0.5rem 1rem; /* Ajustar el padding de los botones */
    }

    .modal-footer-passenger-modal {
        padding: 0.5rem;
    }
    .ctn-email{
        width: 100%;
    }
}

@media ( max-width: 350px){
    .ctn-cod-tel{

        flex-direction: column;
        
    }
    .ctn-tel{
        width: 100%;
    }
    .ctn-cod{
        width: 40%;
    }
    .ctn-tel{
        padding-left: 0px;
    }
   
}