.asiento-disponible {
    background-image: url("../img/bus/asiento-disponible.jpg");
    background-size: cover;
}
.asiento-disponible:hover {
    background-image: url("../img/bus/asiento-seleccionada.jpg");
    background-size: cover;
    cursor:pointer
}

.asiento-ocupado {
    background-image: url("../img/bus/asiento-ocupado.jpg");
    background-size: cover;
}

.asiento-seleccionado {
    background-image: url("../img/bus/asiento-seleccionada.jpg");
    background-size: cover;
}

.bus li.bus-asiento-no-disponible {
    background-image: url("../img/bus/asiento-no-disponible.jpg");
    background-size: cover;
}

.bus li.bus-asiento-seleccionada {
    background-image: url("../img/bus/asiento-seleccionada.jpg");
    background-size: cover;
}
.bus-chofer{
    background-image: url("../img/bus/chofer.jpg");
    background-size: cover;
}
.no-seat {
    background-color: #F5F5F5;
}

.container-seatReferences{
    margin-top: 50px;
}
.btn-continuar:hover{
    color: white;
}



@media (min-width: 992px) and (max-width: 1430px)  {

    .btn-volver-taquilla{
        padding: 7px 14px !important;
    }
    .btn-confirmar-taquilla{
        padding: 7px 14px !important;

    }
    .btn-continuar{
        padding: 7px 14px !important;
        
    }

    .ctn-titles-info-pasaje{
        font-size: 0.7rem;
    }
    .box-info-pasaje{
        padding: 0;
        font-size: 0.6rem;
    }
    .ctn-info-titles .item-company-name h6, .ctn-info-titles h6:last-of-type {
        font-size: 0.6rem;
    } 
    .ctn-info-pasajes{
        padding: 15px;
    }
}
@media (max-width: 992px){
    .ctn-pasaje-list-item{
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.28);
    }
}